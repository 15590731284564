import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import unidueId from 'utils/uniqueId';
import SVG from 'components/base/SVG';

import css from './style.scss';


class Checkbox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || props.defaultChecked,
    };
    this.id = props.id || unidueId();
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(this.props.changeValue || e);
    } else {
      this.setState({ checked: !this.state.checked });
    }
  }

  render() {
    const { id, label, checked, defaultChecked, changeValue, containerClassName, input, meta, icon, color, onInputRef, disabled, labelClassName, onLabelClick, ...rest } = this.props;

    return (
      <div className={classNames(css.checkbox, containerClassName)}>
        <label htmlFor={id} className={css.switch}>
          <input
            {...rest}
            id={id}
            ref={onInputRef}
            className={css.input}
            type="checkbox"
            onChange={this.handleChange}
            checked={this.state.checked}
            disabled={disabled}
            {...input}
          />
          <SVG id={id + '_unchecked'} icon="iconCheckbox" className={css.iconCheckbox} />
          <SVG id={id + '_checked'} icon="iconCheckboxChecked" className={css.iconCheckboxChecked} />
          {!color ? null : <div className={css.color} style={{ backgroundColor: color }} />}
          <span className={classNames(css.label, labelClassName)} onClick={onLabelClick}>
            {!icon ? null : <SVG icon={icon} className={css.iconLabel} />}
            {label}
          </span>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onLabelClick: PropTypes.func,
};

Checkbox.defaultProps = {
  input: {},
  disabled: false,
};


export default Checkbox;
