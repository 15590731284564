import Button from "components/base/Button";
import React from "react";
import newsearchfilter from "./SearchFilterNew.scss";
import Modal from 'components/base/Modal';

import Confirm from 'app/components/Confirm';
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Col, Label } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import addPlus from '../../../../assets/images/add-plus.svg';
import { assignSearchSlot, deleteSearch, loadSearch, setSelectedSaveSearch, setFavoriteItem } from "../../../../data/search";
import { selectFavoriteSearchSlotOptions, selectSavedSearchLoading, selectSavedSearches } from "../../../../data/search/selectors";
import AutoSearchDropdown from './shared/Autosearch';
import inputStyle from './shared/style.scss';


const SaveSearchList = ({closeParent}) => {


  const dispatch = useDispatch();
  const loading = useSelector(selectSavedSearchLoading);
  const getSavedSearches = useSelector(selectSavedSearches).toJS();
  const [activeRow, setActiveRow] = useState(null);
  const [activeRowClassName, setActiveRowClassName] = useState(null);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

  const getDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { weekday: 'short', month: 'numeric', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);

  }

  useEffect(() => {
    const handleScroll = (params) => {
      // Handle scroll
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleRowClick = (event, item) => {
    dispatch(loadSearch(item));
    dispatch(setSelectedSaveSearch(item?.name));
    closeParent(event)

    // update Favorite Selected Name
    dispatch(setFavoriteItem(item));
  };

  const handleDelete = (ev, item) => {
    ev.stopPropagation();
    dispatch(Confirm.open({
      onOk: () => { dispatch(deleteSearch(item.id)); /* Close dropdown */ },
      question: `Are you sure that you want to delete search "${item.name}"?`,
    }));
  };

  const toggleDropdownSave = (index) => {
    setActiveDropdownIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle dropdown state
  };
  
  const getFavoriteSearchCount = () => {
    // Find all elements with a class name containing "__dashboardSearchItem"
    // This helps identifying the visible search buttons
    const searchItems = document.querySelectorAll(
      '[class*="__dashboardSearchItem"]',
    );
    return searchItems.length;
  }


  return (
    <>
      <div className={`${newsearchfilter.clsSearchMenuPopup} ${getFavoriteSearchCount() < 4 ? newsearchfilter.leftAlign : ''}`}>
        <div className={newsearchfilter.clsInnerScroll}>
          <ul className={newsearchfilter.clsCardBody}>
            {getSavedSearches?.map((option, index) => (
              <li className={newsearchfilter.clsCardBodyList} key={index}>
                <SavedSearchAssignFilter 
                item={option}
                isOpen={activeDropdownIndex === index}
                toggleDropdown={() => toggleDropdownSave(index)}
                />
                <div className={newsearchfilter.clsContBody} onClick={(event) => handleRowClick(event, option)}>
                  <h4 className={newsearchfilter.clsHedTxt}> {option.name} {option.description}</h4>
                  <p className={newsearchfilter.clsSubTxt}> {option.totalCount > 99999 ? '99,999+' : option.totalCount}  Unique Properties</p>
                </div>
                <p className={newsearchfilter.clsDateTxt} onClick={(event) => handleRowClick(event, option)}>
                  {getDate(option.createDate)}
                </p>
                { option.locked ? (
                 <span className={newsearchfilter.clsRemoveDeleteBtn}>

                 </span>
                ) : (
                  <Button
                  kind="link-default"
                  className={newsearchfilter.clsDeleteBtn}
                  size="large"
                  onClick={(event) => handleDelete(event, option)}
                >
                  Delete
                </Button>
                )}
              </li>
            ))}

          </ul>
        </div>
      </div>
    </>
  )
}





const SavedSearchAssignFilter = ({ item , isOpen, toggleDropdown }) => {

  const dispatch = useDispatch();
  const dropdownSavedSearchRef = useRef(null);
  const [isSavedSearchOpen, setIsSavedSearchOpen] = useState(false);
  const slots = useSelector(selectFavoriteSearchSlotOptions) || [];

  const getSlotOptions = () => {
    if (slots && slots?.length > 0) {
      const updatedOptions = [];
      slots.forEach(type => {
        updatedOptions.push({
          id: type.id,
          name: type.label,
          value: type.label,
          label: type.label,
        })
      })
      return updatedOptions;
    } else {
      return [];
    }
  }

  const [slotOptions, setSlotOptions] = useState(getSlotOptions() || []);

  const toggleDropdownSave = (event) => {
    event.stopPropagation();
    setIsSavedSearchOpen(prevIsOpen => !prevIsOpen);
  };

  const handleMenuClick = event => {
    event.stopPropagation(); // Prevent click event from propagating to document click listener
  };



  useEffect(() => {
    setSlotOptions(getSlotOptions());
  }, [slots])

  //   const { index } = useState(null);
  const [index, setIndex] = useState(1);
  const [popupPosition, setPopupPosition] = useState(0)

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const slot = slots.find(s => s.value === index);

    const save = () => {
      dispatch(assignSearchSlot(item.id, index));
      parentChildClose()
    };

    if (slot.id) {
      dispatch(Confirm.open({ question: `Do you want to replace "${slot.label}" with "${item.name}"?`, onOk: save }));
    } else {
      save();
    }
  };

  const handleSlotChange = (selectedOption) => {
    const slotIndex = slots.findIndex(s => s.label === selectedOption);
    setIndex(slotIndex + 1)
  }

  const closeCurrent = (ev) => {
    ev.stopPropagation();
    toggleDropdown()
    setIsSavedSearchOpen(false);
  }

  const parentChildClose = () => {
    setIsSavedSearchOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownSavedSearchRef.current && !dropdownSavedSearchRef.current.contains(event.target)) {
        setIsSavedSearchOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  return (
    <div ref={dropdownSavedSearchRef} className={newsearchfilter.dropdownContainer}>
      <Button
        kind="link-default"
        className={`${newsearchfilter.clsAddBtn} ${isOpen ? newsearchfilter.focused : ''}`}
        size="large"
        onClick={(event) => {
          const contPosition = dropdownSavedSearchRef.current.getBoundingClientRect().x;

          if( event.clientY < 400 ){
            setPopupPosition( event.clientY - ( contPosition > 750 ? 87 : 25 ) );
          }else{
            setPopupPosition( event.clientY - ( contPosition > 750 ? 500 : 430 ) );
          }
          event.stopPropagation();
          toggleDropdown(); // Use passed toggle function
        }}
      >
        <img src={addPlus} id={newsearchfilter.idAddBtn} alt="Add" />
      </Button>
        <Modal isOpen={isOpen} className={newsearchfilter.clsHomeScreenPopup} style={{'top':`${popupPosition}px`, 'bottom':'auto'}}>
          <h4 className={newsearchfilter.clsHeading}>Add to Home Screen</h4>
          <div className={newsearchfilter.pb_24}>
            <Label className={newsearchfilter.clsLabel}>Name</Label>
            <h3 className={newsearchfilter.clsNameTxt}>{item.name} {item.description}</h3>
          </div>
          <div className={newsearchfilter.pb_24}>
            <Label className={newsearchfilter.clsLabel}>Select spot to replace</Label>

            <Col className={inputStyle.clsCol}>
              <h4 className={inputStyle.clsSubHeading}>&nbsp;</h4>
              <div onClick={handleMenuClick} className={classNames(inputStyle.flexBoxNowrap, inputStyle.clsFilterSelect)}>
                <AutoSearchDropdown items={slotOptions} defaultValue={slotOptions?.length > 0 && slotOptions[index - 1]?.value} onChangeValue={handleSlotChange} 
                searchDisabled={true}
                />

              </div>
            </Col>


          </div>
          <div className={newsearchfilter.text_right}>
            <Button kind="link-default" className={classNames(newsearchfilter.clsPopupBtn, newsearchfilter.clsCancelBtn)} size="large" onClick={closeCurrent}> Cancel</Button>
            <Button kind="link-default" className={classNames(newsearchfilter.clsPopupBtn, newsearchfilter.clsSaveBtn)} size="large" onClick={handleSubmit}> Save</Button>
          </div>
        </Modal>
    </div>
  );
};

export default SaveSearchList