import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter,
  selectLocalFilter
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss";
import {
  DaysMarketOptions,
  IncludeExcludeOptions,
  ListingAmountOptions,
  MlsListingStatus,
  MlsListingTypeOptions,
  MlsStatusDateOptions,
  OnMarketOption,
  YesNoOptions,
  fieldTagsMap,
  formatDateShortWithUTC
} from "../../shared/ConstantsNew";
import SelectAnyoneByMinMax from "../../shared/SelectAnyoneDate/SelectAnyoneByMinMax";
import ToggleSection from "../../shared/ToggleSection";
import ToggleList from "../../shared/ToggleSection/ToggleList";
import { useFilter } from "../../TagFilterContext";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";

import FilterInputComponent from "../../shared/Input";
import moment from "moment";
import { generateMinMaxDateValue, generateMinMaxValue, getMinMaxDateToUpdate, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";


const MLSscreen = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});
  const maxLength = 10
  const [mls, setMLSValues] = useState({
    forSale: null,
    rental: null,
    mlsListingStatus: null,
    mlsListingDate: null,
    daysOnMarketMin: null,
    daysOnMarketMax: null,
    mlsListingAmountMin: null,
    mlsListingAmountMax: null,
    belowMarketPrice: null,
    mlsListingKeyword: null,
    mlsListingDateMin: null,
    mlsListingDateMax: null,
  });

  useEffect(() => {
    if (filter) {
      setMLSValues(filter);
      setFilterApplied(true);
    }
  }, [filter]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnMls();
    }
  }, [isFilterApplied]);

  const getBooleanOption = (value, options) => {
    return options.find(option => option.value === value) || null;
  };

  const applyFiltersBasedOnMls = () => {
    const fieldsToCheck = [
      // Boolean fields
      { label: "On Market", value: mls.forSale, key: "forSale", isBoolean: true, options: OnMarketOption },
      { label: "Listing Type", value: mls.rental, key: "rental", isBoolean: true, options: MlsListingTypeOptions },
      { label: "Listed Below Market Price", value: mls.belowMarketPrice, key: "belowMarketPrice", isBoolean: true, options: IncludeExcludeOptions },

      // Text fields
      { label: "MLS Status", value: mls.mlsListingStatus, key: "mlsListingStatus", isText: true },
      { label: "MLS Keyword(s)", value: mls.mlsListingKeyword, key: "mlsListingKeyword", isText: true },

      // Date fields
      { label: "MLS Status Date", min: mls.mlsListingDateMin, max: mls.mlsListingDateMax, key: "mlsListingDate", isDate: true },

      // Range fields
      { label: "Days On Market", min: mls.daysOnMarketMin, max: mls.daysOnMarketMax, key: "daysOnMarket" },
      { label: "MLS Listing Amount", min: mls.mlsListingAmountMin, max: mls.mlsListingAmountMax, key: "mlsListingAmount" },
    ];

    fieldsToCheck.forEach(({ label, min, max, key, isBoolean, isText, isDate, value, options }) => {
      if (isBoolean) {
        if (value !== null && value !== '' && value !== undefined) {
          // handleBooleanSelectPCNew({value:value}, key, label);
          const option = getBooleanOption(value, options);
          if (option) {
            handleBooleanSelectPCNew(option, key, label);
          } else {
            // Handle the case where no valid option is found
            handleOldRemoveFilter(key);
            handleClearFilter(label);
          }
        }
      }
      else if (isText) {
        if (value !== null && value !== '' && value !== undefined) {
          inputValueChange(value, label, key);
        }
      }
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboDateValueChange(min, max, label, key);
        }
      }
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboBoxValueChange(min, max, label, key);
        }
      }
    });
  };

  const getEditOptions = useSelector(selectShowAllEditOption)
  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'mls') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);

  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    updateFilterOldStore(key, option.value);
    updateFilterStore(option, key, leftLabel);
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = "";
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }

      const selectedIds = selectedOptions.map(element => element.id).join(",");
      const fullSeletedNamesList = selectedOptions
        .map(element => element.name)
        .join(",");
      updateFilterOldStore(key, selectedIds);
      // meed to work morning
      updateFilterStore(
        { value: key, label: name },
        key,
        leftValue,
        fullSeletedNamesList
      );
    } else {
      handleOldRemoveFilter(key);
      handleClearFilter(leftValue);
    }
  };

  const inputValueChange = (value, lable, key) => {
    updateFilterOldStore(key, value);
    updateFilterStoreCombox(value, key, lable);
  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);
    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };


  // combo date
  const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {
    const name = generateMinMaxDateValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxDateToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };

  //  store
  // store
  const updateFilterOldStore = (key, id) => {
    // if (id) {
    dispatch(updateFilter({ [key]: id }));
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "mls",
          id: leftLabel,
          name: option.label,
          apiKey: key,
          [key]: option.value,
          removeID: key,
          showALLDisplayValue: fullSeletedNamesList
        }
      ])
    );
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "mls", id: leftLabel, name: name, apiKey: key,
          removeID: key,
          showALLDisplayValue: name
        }
      ])
    );
    // }
  };

  const handleClearFilter = id => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = id => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const mlsFilters = getLocalFilter.filter(filter => filter.key === 'mls');
    return `${newsearchfilter.clsShowOnTag}`;
    // console.log(mlsFilters)

    // const mlsFilterNames = mlsFilters.map(filter => filter.id);
    // if (selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag)) || mlsFilterNames.includes(fieldLabel)) {
    //   return `${newsearchfilter.clsShowOnTag}`;
    // } else if (selectedTags.length === 0) {
    //   return `${newsearchfilter.clsShowOnTag}`;
    // } else  {
    //   return `${newsearchfilter.clsHideOnTag}`;
    // }
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  return (
    <div className={classNames(
      getClassNames('On Market'),
      getClassNames('Listing Type'),
      getClassNames('MLS Status'),
      getClassNames('MLS Satus Date'),
      getClassNames('Days On Market'),
      getClassNames('MLS Listing Amount'),
      getClassNames('Listed Below Market Price'),
      getClassNames('MLS Keyword(s)')
    )}>
      <div className={newsearchfilter.clsContentBody}>
        <div className={newsearchfilter.headerBg}>
          <h2 className={newsearchfilter.clsHeading}>MLS</h2>
        </div>

        <div className={classNames(newsearchfilter.pb_28, getClassNames("On Market"), getClassNames("Listing Type"))}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("On Market")}>
              <div id="forSale" ref={el => refs.current['On Market'] = el}></div>
              <ToggleList
                headerTitle={"On Market"}
                headerTitleShow={true}
                backGroudGray={false}
                selected={mls.forSale}
                options={OnMarketOption}
                isHighlightIndex={filteredFields['On Market'] === navIndex ? true : false}
                headerClassName={isFilteredSection("On Market") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={selectedOption =>
                  handleBooleanSelectPCNew(
                    selectedOption,
                    "forSale",
                    "On Market"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Listing Type")}>
              <div id="rental" ref={el => refs.current['Listing Type'] = el}></div>
              <ToggleList
                headerTitle={"Listing Type"}
                headerTitleShow={true}
                backGroudGray={false}
                selected={mls.rental}
                options={MlsListingTypeOptions}
                isHighlightIndex={filteredFields['Listing Type'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Listing Type") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={selectedOption =>
                  handleBooleanSelectPCNew(
                    selectedOption,
                    "rental",
                    "Listing Type"
                  )
                }
              />
            </Col>
          </Row>
        </div>

        <div
          className={classNames(
            newsearchfilter.pb_28,
            newsearchfilter.clsBorderTop,
            getClassNames("MLS Status")
          )}
        ></div>

        <div id="mlsListingStatus" className={classNames(newsearchfilter.pb_28, getClassNames("MLS Status"))} ref={el => refs.current['MLS Status'] = el}>
          <div className={newsearchfilter.clsToggleMarginMins}>
            <ToggleSection
              headerTitle={"MLS Status"}
              options={MlsListingStatus}
              selectedIds={mls.mlsListingStatus}
              isHighlightIndex={filteredFields['MLS Status'] === navIndex ? true : false}
              headerClassName={isFilteredSection("MLS Status") ? newsearchfilter.filteredSubTitle : ""}
              onSelectClickAction={selectedOptions =>
                handleSelectAllClick(
                  selectedOptions,
                  "MLS Status",
                  "mlsListingStatus"
                )
              }
            />
          </div>
        </div>

        <div
          className={classNames(
            newsearchfilter.pb_28,
            newsearchfilter.clsBorderTop,
            getClassNames("MLS Satus Date")
          )}
        ></div>


        <div id="mlsListingDate" className={classNames(newsearchfilter.pb_28, getClassNames("MLS Status Date"))} ref={el => refs.current['MLS Status Date'] = el}>
          <SelectAnyoneByMinMax
            headerTitle={'MLS Status Date'}
            box2Placeholder={'Select Range'}
            defaultValues={{
              min: mls.mlsListingDateMin,
              max: mls.mlsListingDateMax
            }}
            orText={'or'}
            options={MlsStatusDateOptions}
            isHighlightIndex={filteredFields['MLS Status Date'] === navIndex ? true : false}
            headerClassName={isFilteredSection("MLS Status Date") ? newsearchfilter.filteredSubTitle : ""}
            onValueChange={(fromDate, toDate) => {
              if (fromDate || toDate) {
                comboDateValueChange(fromDate, toDate, 'MLS Status Date', 'mlsListingDate');
              } else {
                // Handle case when neither fromDate nor toDate is provided
                handleOldRemoveFilter('mlsListingDate');
                handleClearFilter('MLS Status Date');
              }

            }}
          />

        </div>

        <div id="daysOnMarket" className={classNames(newsearchfilter.pb_28, getClassNames("Days On Market"), getClassNames("MLS Listing Amount"))}
          ref={el => refs.current['Days On Market'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Days On Market")}>
              <FilterComboBox
                boxTittle={"Days On Market"}
                minOptions={DaysMarketOptions}
                maxOptions={DaysMarketOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxLength={maxLength}
                isHighlightIndex={filteredFields['Days On Market'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Days On Market") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: mls.daysOnMarketMin,
                  max: mls.daysOnMarketMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Days On Market",
                    "daysOnMarket"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("MLS Listing Amount")}>
              <div id="mlsListingAmount" ref={el => refs.current['MLS Listing Amount'] = el}></div>
              <FilterComboBox
                boxTittle={"MLS Listing Amount"}
                minOptions={ListingAmountOptions}
                maxOptions={ListingAmountOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['MLS Listing Amount'] === navIndex ? true : false}
                headerClassName={isFilteredSection("MLS Listing Amount") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: mls.mlsListingAmountMin,
                  max: mls.mlsListingAmountMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "MLS Listing Amount",
                    "mlsListingAmount"
                  )
                }
              />
            </Col>
          </Row>
        </div>
        <div className={classNames(newsearchfilter.pb_28, getClassNames("Listed Below Market Price"), getClassNames("MLS Keyword(s)"))}>
          <div id="belowMarketPrice" ref={el => refs.current['Listed Below Market Price'] = el}></div>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Listed Below Market Price")}>
              <ToggleList
                headerTitle={"Listed Below Market Price"}
                backGroudGray={false}
                selected={mls.belowMarketPrice}
                options={YesNoOptions}
                isHighlightIndex={filteredFields['Listed Below Market Price'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Listed Below Market Price") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={selectedOption =>
                  handleBooleanSelectPCNew(
                    selectedOption,
                    "belowMarketPrice",
                    "Listed Below Market Price"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("MLS Keyword(s)")}>
              <div id="mlsListingKeyword" ref={el => refs.current['MLS Keyword(s)'] = el}></div>
              <FilterInputComponent
                boxTittle={"MLS Keyword(s)"}
                placeHolder={"Enter MLS Keywords"}
                isHighlightIndex={filteredFields['MLS Keyword(s)'] === navIndex ? true : false}
                headerClassName={isFilteredSection("MLS Keyword(s)") ? newsearchfilter.filteredSubTitle : ""}
                defaultValue={mls.mlsListingKeyword ? mls.mlsListingKeyword : ''}
                onChange={value =>
                  inputValueChange(
                    value,
                    "MLS Keyword(s)",
                    "mlsListingKeyword"
                  )
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MLSscreen;
