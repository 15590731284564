import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Radio from 'components/base/Radio';
import Confirm from 'app/components/Confirm';
import FormControlWraper from 'components/base/FormControlWraper';
import SVG from 'components/base/SVG';
import Checkbox from 'components/base/Checkbox';
import formatServerUrl, { campaignWebsiteUrl } from 'utils/URL';
import { campaignWebsitePath } from 'app/routes';
import { isValidEmail } from 'utils/validation';
import { selectProfile } from 'data/user';
import {
  updateWebsite,
  selectCampaign,
  selectLoading,
  selectWebsite,
  selectWebsiteTemplates,
  checkWebsiteAvailability,
  loadWebsiteTemplates,
  saveWebsite
} from 'data/campaigns';
import { withRouter } from 'react-router-dom';

import css from './style.scss';


class WebsiteSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.publishWebsite = this.publishWebsite.bind(this);

    this.timer = null;
    this.state = {
      loaded: false,
      path: '',
      contactEmail: '',
      template: null,
      templateEnabled: false,
      available: null,
    };
  }

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.handleProps(props);
  }

  handleProps(props) {
    const { campaign, website, loading, templates, loadWebsiteTemplates, profile } = props;
    if (!loading) {
      if (!templates) loadWebsiteTemplates();
      else if (!this.state.loaded && campaign.get('id')) {
        this.setState({
          loaded: true,
          path: String(website.get('path') || campaign.get('id')),
          contactEmail: website.get('contactEmail') || profile.get('username'),
          templateEnabled: website.get('templateEnabled'),
          initMode: !website.get('elements').size,
        }, () => this.checkAvailability());
      }
    }
  }

  handleSave() {
    const { website, confirm, updateWebsite, handleToggleStatusClick } = this.props; // , postcardCampaignChange, campaign
    const { path, contactEmail, templateEnabled, initMode, template, available } = this.state;

    let message;
    if (path.trim() === '') message = 'Website Name is required.';
    else if (available !== true) message = `Website Name "${path}" is not available. Please enter a different name.`;
    else if (contactEmail.trim() === '') message = 'Contact Email is required.';
    else if (!isValidEmail(contactEmail.trim())) message = 'Contact Email is invalid.';

    if (message) confirm({ cancelLabel: null, question: message });
    else {
      let w = website.merge({ path, contactEmail, templateEnabled });
      if (initMode && template) {
        const { headerText, bodyText, elements } = template.toJS();
        w = w.merge({
          headerText,
          bodyText,
          elements: elements.map(e => ({ ...e, id: null }))
        });
      }

      w = w.merge({
        active: true
      });

      updateWebsite(w);
      this.publishWebsite(w);
      this.handleClose();
    }
  }

  publishWebsite(website) {
    const { campaign, saveWebsite } = this.props;
    saveWebsite(campaign.get('id'), website);
  }

  killTimer() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }

  handleChange(ev) {
    if (!ev.target) this.setState(ev);
    else {
      const { name, value } = ev.target || ev;
      let val = value;

      if (name === 'path') {
        val = val.replace(/[^a-zA-Z0-9_]/g, '');
        if (val === this.state.path) return;

        this.setState({ available: null });
        this.killTimer();
        this.timer = setTimeout(() => {
          this.killTimer();
          this.checkAvailability();
        }, 1000);
      }

      this.setState({ [name]: val });
    }
  }

  checkAvailability() {
    const { website, checkWebsiteAvailability } = this.props;
    const path = this.state.path.trim();

    if (path === '') this.setState({ available: null });
    else checkWebsiteAvailability(website.get('id'), path, ({ response }) => this.setState({ available: !!response }));
  }

  handleClose() {
    const { campaign, history } = this.props;
    history.push(campaignWebsitePath(campaign));
  }

  render() {
    const { loading, templates } = this.props;
    const { path, contactEmail, templateEnabled, available, initMode, template: selectedTemplate } = this.state;

    const btn = { size: Button.size.large, kind: Button.kind.grayGhost, isLoading: loading };

    return (
      <Modal
        isOpen
        uniqId="WebsiteSettings"
        padding="15px"
        caption="Website Settings"
        width="580px"
        onClose={this.handleClose}
        outerClassName={css.popup}
      >
        <div className={css.settings}>
          <div className={css.options}>
            <div className={css.option}>
              <FormControlWraper label="Website Name" large>
                <input type="text" onChange={this.handleChange} name="path" value={path} />
              </FormControlWraper>
              {path.trim() === '' ? null : (
                <div className={css.subContainer}>
                  <div className={css.sub}>{campaignWebsiteUrl(path)}</div>
                  {available == null ? null : (
                    <div className={css.status}>
                      <SVG icon={available ? 'iconCorrect' : 'iconIncorrect'} className={css.statusIcon} />
                      <div className={classNames(css.statusText, { [css.statusWrong]: !available })}>This name is {available ? '' : ' not'} available.</div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={css.option}>
              <FormControlWraper label="Contact Email" large>
                <input type="text" onChange={this.handleChange} name="contactEmail" value={contactEmail} />
              </FormControlWraper>
            </div>
            <div className={css.option}>
              <FormControlWraper label="Enable Website Template" large>
                <div className={css.inlineRadio}>
                  <Checkbox checked={templateEnabled} onClick={ev => this.handleChange({ templateEnabled: !!ev.target.checked })} label="Check this box if you would like this website to be available as a template for future websites." />
                </div>
              </FormControlWraper>
            </div>
            <div className={css.option}>
              <FormControlWraper label="Apply Website Template" large>
                <div>
                  <Radio onChange={() => this.handleChange({ template: null })} label={initMode ? 'Start with a blank website.' : 'Do not apply a template.'} checked={!selectedTemplate} />
                  <div>
                    <Radio onChange={() => this.handleChange({ template: templates.get(0) })} label={initMode ? 'Start with a pre-defined website template.' : 'Apply a pre-defined website template.'} checked={!!selectedTemplate} />
                    {!selectedTemplate ? null : (
                      <div>
                        {initMode ? null : <div className={css.templateWarning}>Template will only be applied to a blank website. Please ensure that all text and images have been cleared.</div>}
                        <div className={css.items}>
                          {templates.map((template, i) => (
                            <div
                              key={template.get('id')}
                              className={classNames(css.item, { [css.selected]: template === selectedTemplate })}
                              onClick={() => { this.handleChange({ template }); }}
                            >
                              <div className={css.template}>
                                <img src={formatServerUrl(template.get('thumbnailUrl'))} alt={`Template ${i + 1}`} />
                                <div className={css.itemCaption}>Template {i + 1}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </FormControlWraper>
            </div>
          </div>
          <div className={css.buttons}>
            <Button {...btn} onClick={this.handleClose}>Cancel</Button>
            <Button {...btn} kind={Button.kind.blue} onClick={this.handleSave}>Done</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(state => ({
  campaign: selectCampaign(state),
  loading: selectLoading(state),
  website: selectWebsite(state),
  templates: selectWebsiteTemplates(state),
  profile: selectProfile(state),
}), {
  updateWebsite,
  saveWebsite,
  checkWebsiteAvailability,
  loadWebsiteTemplates,
  confirm: Confirm.open,
})(WebsiteSettings));
