import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { SearchLayouts, SearchOverlays } from 'data/search';
import Paginator from 'components/Paginator';
import SVG from 'components/base/SVG';
import Image from 'components/base/Image';
import DEFAULT_IMAGE_URL from 'assets/favicon.png';
import Table from 'components/base/Table';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import { MinMaxInput } from 'components/base/Input';
import { SearchFields, SearchNames, SearchTables, PicViewFields, SearchTypes, SearchDataType, SearchFullTable, SearchLiensTable, SearchTypeCustom, SearchTypeDefault } from 'data/search';
import formatDate from 'utils/date/formatDate';
import formatDateYearMonth from 'utils/date/formatDate';
import numberFormat from 'utils/number/format';
import numberToPrice from 'utils/currency/numberToPrice';
import stopPropagation from 'utils/DOM/propagation';

import css from './style.scss';
import ColumnsRenderers from '../Columns';
import { isNDAState } from '../../../utils/ndaStates';


const Characteristics = [
  { field: 'bedrooms', label: 'Bd.' },
  { field: 'bathrooms', label: 'Ba.' },
  { field: 'squareFeet', label: 'SqFt.', formatter: numberFormat },
  { field: 'lotSquareFeet', label: 'Lot SqFt.', formatter: numberFormat },
];

const BIG_COUNTER = (10 ** 3) - 10;

const btn = { size: Button.size.small, kind: Button.kind.blueGhost };

const Results = ({ onChangePage, onChangeRange, onSelect, onSaveList, result, filter, activeContext: ac, onSort, onClick, sort, activeMarker }) => {
  const results = ac.get('displayResults');
  const resultCount = ac.get('resultCount');
  const selectionCount = ac.get('selectionCount');
  const allSelected = ac.get('allSelected');
  const rangeFrom = ac.get('rangeFrom');
  const rangeTo = ac.get('rangeTo');
  const rangeEnabled = ac.get('rangeEnabled');
  const pageIndex = ac.get('pageIndex');
  const pageCount = ac.get('pageCount');

  const type = filter.get('type');

  let columns;
  if (result.get('overlay') === SearchOverlays.Property) columns = SearchTables[type === 'L' ? SearchLiensTable : SearchFullTable];
  else if (result.get('layout') === SearchLayouts.Grid) columns = SearchTables[SearchTypes[type] || SearchTypeCustom] || SearchTables[SearchTypeDefault];
  
  return (
    <div className={css.wrapper}>
      {!resultCount ? null : (
        <div className={css.resultsHeader}>
          <div className={css.left}>
            <Checkbox id={'unique_properties'} checked={allSelected} onChange={onSelect} />
            {rangeEnabled ? null : <div className={css.caption}>Unique Properties{rangeEnabled ? '' : ` (${resultCount})`}</div>}
            {!rangeEnabled ? <div className={css.selected}>{!selectionCount ? null : `${selectionCount} Selected`}</div> : (
              <div className={css.range}>
                <div>Show</div>
                <MinMaxInput name="rangeFrom" placeholder="1" value={rangeFrom} min={1} max={rangeTo || resultCount} onChange={onChangeRange} />
                <div>-</div>
                <MinMaxInput name="rangeTo" placeholder={resultCount} value={rangeTo} min={rangeFrom || 1} max={resultCount} onChange={onChangeRange} />
                <div>of {resultCount} Properties</div>
              </div>
            )}
          </div>
          {!rangeEnabled ? null : <div className={css.selectedBottom}>{selectionCount ? `${selectionCount} Selected` : null}</div>}
          <div className={css.right}>
            {rangeEnabled ? null : <Button {...btn} onClick={() => onChangeRange({ name: 'rangeEnabled', value: true })}>Filter</Button>}
            <Button {...btn} onClick={onSaveList} disabled={!selectionCount}>Add to List</Button>
          </div>
        </div>
      )}
      <div className={css.view}>
        {resultCount ? null : (
          <div className={css.container}>
            <h3>No Matching Results.</h3>
            <h4>Please try changing or expanding your search criteria.</h4>
          </div>
        )}
        {!resultCount ? null : (
          <div className={css.content}>
            {!columns ? null : (
              <Table data={results} onRowClick={onClick} onColumnSort={onSort} sortFields={sort} keyField="id" className="type_11" isSortable isHoverable>
                {ColumnsRenderers.CHECKBOX({ onSelect })}
                {columns.map(
                  column => ColumnsRenderers[column]({ activeMarker }, <SVG icon={ac.get('sortAscending') ? 'iconCaretUp' : 'iconCaretDown'} />),
                )}
              </Table>
            )}
            {columns ? null : (results.map((item) => {
              const id = item.get('id');
              const link = `/search/${item.get('propertyId')}`;
              const index = item.get('resultIndex');

              let type = item.get('type');

              if (type === 'P' && item.get('auctionDate')) type = 'A';
              else if (type === 'I') type = 'M';

              return (
                <div key={`${type}${item}`} className={classNames(css.item, { [css.itemChecked]: item.get('selected'), [css.bigCounter]: index >= BIG_COUNTER })} onClick={() => onClick(id)}>
                  <div className={css.checkboxContainer}>
                    <Checkbox onChange={onSelect} changeValue={id} checked={item.get('selected')} />
                  </div>
                  <span className={css.index}>{index}</span>
                  <div className={css.image}>
                    <div className={css.mask}>
                      <Link to={link} onClick={stopPropagation}>
                        <Image src={item.get('imageUrl') || DEFAULT_IMAGE_URL} alt={item.getIn(['address', 'streetAddress'])} />
                      </Link>
                    </div>
                    <div className={css.type}>{SearchNames[type]}</div>
                  </div>
                  <div className={css.info}>
                    <div className={css.header}>
                      <div className={css.left}>
                        <Link to={link} className={css.name} onClick={stopPropagation}>{item.getIn(['address', 'streetAddress'])}</Link>
                        <div>{item.getIn(['address', 'cityName'])}</div>
                        <div>{item.getIn(['address', 'stateCode'])} {item.getIn(['address', 'zip'])}</div>
                        <div className={css.apn}>APN: {item.get('apn')}</div>
                      </div>
                      <div className={css.right}>
                        {!item.get('estimatedValue') ? null : (
                          <div className={css.value}>
                            <div>Est. Value</div>
                            <div>{numberToPrice(item.get('estimatedValue'))}</div>
                          </div>
                        )}
                        <div className={css.characteristics}>
                          <div className={css.type}>{item.get('landUse')}</div>
                          {Characteristics.filter(c => !!item.get(c.field)).map(c => (
                            <span className={css.option} key={c.field}>
                              <span className={css.value}>{(c.formatter || (c => c))(item.get(c.field))}</span> {c.label}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className={css.footer}>
                      {(PicViewFields[type] || PicViewFields[SearchTypeCustom]).map(({ type, value, label }, index) => {
                        const field = SearchFields[value];
                        let val = item.get(field);

                        if (val || val === 0) {
                          // if (type === SearchDataType.DATE) val = formatDate(val, null, null, item.get(`${field}Format`));
                          if (type === SearchDataType.DATE) val = formatDate(val, null, null, 'MM/DD/YYYY');
                          else if (type === SearchDataType.PRICE) val = numberToPrice(val);
                        } else if (item.get('type') !== 'L') val = null;

                        return (
                          <div className={classNames(css.field, { [css.hidden]: !val })} key={`${id}-${index}`}>
                            <span className={css.title}>{label}:</span>
                            <span className={css.value}>{(label === 'Sale Price' && val) ? `${val} ${isNDAState(item.getIn(['address', 'stateCode'])) ? 'Est.' : ''}` : val}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            }))}
          </div>
        )}
      </div>
      <Paginator current={pageIndex} total={pageCount} onChangePage={onChangePage} />
    </div>
  );
};

export default Results;
