import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withProperty from 'app/components/withProperty';
import moment from 'moment';
import { fromJS, List, Map as ImMap } from 'immutable';
import { bind, unbind } from 'utils/DOM/scrollSpy';
import { AgGridReact } from 'ag-grid-react';
import formatDate from 'utils/date/formatDate';
import InputDate from 'components/base/InputDate';
import Button from 'components/Button';
import { RedLink } from 'components/Link';
import FormControlWraper from 'components/base/FormControlWraper';
import Map from 'app/components/Map';
import Confirm from 'app/components/Confirm';
import Dropdown, { CheckboxDropdown } from 'components/base/Dropdown';
import DropdownMenu from 'components/base/DropdownMenu';
import Radio from 'components/base/Radio';
import ColorDot from 'components/ColorDot';
import { MlsListingStatus, LienTypeCode, BankruptcyChapterOptions, PreForeclosureStatusOptions } from 'data/search';
import SVG from 'components/base/SVG';
import LoadablePanel from 'components/hoc/loadable/Panel';
import AddToGroup from 'app/components/AddToGroup';
import GridLayoutEditor from 'app/components/GridLayoutEditor';
import { selectProfile, selectLayouts } from 'data/user';
import { AddressCellRenderer, handleSurroundingTypeChange } from 'utils/grid';

import {
  selectComparableLoading,
  updateSurroundingPropertySearch,
  resetSurroundingPropertySearch,
  searchComparables,
  searchNeighbors,
  searchFlipComparables,
  searchSurroundingProperties,
  downloadComparables,
  exportComparables,
  exportFlipComparables,
  saveSurroundingSearch,
  sortSurroundingProperties,
  YesNoOptions,
  ExcludeIncludeOptions,
  PurchaseMethodOptions,
  SaleSituationOptions,
  updateSurroundingPropertySelection,
  updateSurroundingPropertyType,
  selectSurroundingPropertyStale,
  selectSurroundingPropertyType,
  selectSurroundingPropertyTypes,
  PropertyGroupTypes,
  PropertyClassOptions,
  PropertyTypeOptions,
  selectOriginalLists,
  selectOriginalFavorites,
  selectLoading,
  saveProperties,
  SaleSituations,
} from 'data/property';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import DetailCellRenderer from './detailCellRenderer';
import IndexCellRenderer from './indexCellRenderer';
import IndexHeaderRenderer from './indexHeaderRenderer';
import GalleryCellRenderer from './galleryCellRenderer';
import Panel from '../Detail/Panel';
import { SurroundingTypes } from '../Detail/Table';

import css from './style.scss';


const defaultPosition = { position: 'relative' };

const load = (props) => {
  const { searchSurroundingProperties, searchComparables, searchNeighbors, searchFlipComparables, propertyId, context, stale, type, types, embedded, onSearchComplete, savedPropertyId } = props;

  if (propertyId && stale) {
    let search = type.get('search');
    const activeType = type.get('type');

        const dateFields = ['saleDateMax', 'saleDateMin', 'recordingDateMin', 'recordingDateMax', 'auctionDateMin', 'auctionDateMax'];
        dateFields.forEach(field => {
            if (moment.isMoment(search.get(field))) {
                search = search.set(field, search.get(field).valueOf());
            }
        });

    if (activeType === 'FLIP') searchFlipComparables(propertyId, context, search, onSearchComplete);
    else if (activeType === 'NEIGHBOR') searchNeighbors(propertyId, context, search, onSearchComplete);
    else if (activeType !== 'COMP') searchSurroundingProperties(propertyId, context, type.get('type'), search, onSearchComplete);
    else {
      searchComparables(propertyId, context, search, (context) => {
        if (onSearchComplete) onSearchComplete(context);
        if (!embedded && types.getIn([1, 'loading'])) searchSurroundingProperties(propertyId, context, null, null, onSearchComplete, savedPropertyId);
      });
    }
  }
};

const getColumns = (props, newLayout) => {
  const { property, layouts } = props;
  const { type, search: { listingType } = {} } = props.type.toJS();
  const typeCode = (type === 'COMP' && listingType) || type;

  let layout = newLayout;
  if (!layout) {
    const id = (property.get('layouts', List()).find(l => l.get('listingType') === typeCode) || ImMap()).get('layoutId');
    layout = layouts.find(l => l.id === id) || layouts.find(l => l.listingType === typeCode && l.defaultLayout);
  }

  const fieldMap = !layout ? {} : layout.fields.reduce((m, f) => ({ ...m, [f.name]: f }), {});

  const columns = SurroundingTypes[typeCode].columns.map((c) => {
    const { name, seq, pin, width, hidden } = fieldMap[c.field] || {};

    return { ...c, ...(name ? { seq, pin, width, hidden } : {}) };
  }).sort((a, b) => a.seq - b.seq);

  return { columns, layoutId: layout ? layout.id : null };
};

const fixedColumns = [
  { headerName: '', field: 'selected', width: 100, pinned: 'left', cellRenderer: 'indexCellRenderer', headerComponent: 'indexHeaderRenderer', sortable: false },
  // { headerName: '', field: 'propertyId', width: 60, pinned: 'left', sortable: false, rowGroup: true },
];

const getColumnDefs = columns => (
  fixedColumns.concat(columns.map(({ name, title, field, hidden, formatter, renderer, pinned, width }) => ({
    headerName: title || name,
    field,
    width: width || 80,
    hide: hidden,
    valueFormatter: formatter,
    cellRenderer: renderer,
    pinned,
  })))
);

class SurroundingProperties extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleViewReportClick = this.handleViewReportClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleGridReady = this.handleGridReady.bind(this);
    this.handleScrollModal = this.handleScrollModal.bind(this);
    this.setContentRef = this.setContentRef.bind(this);
    this.handleSaveGroup = this.handleSaveGroup.bind(this);
    this.handleSaveList = this.handleSaveList.bind(this);
    this.handleGridLayoutApply = this.handleGridLayoutApply.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.handleColumnDragStopped = this.handleColumnDragStopped.bind(this);
    this.handleGridRowClicked = this.handleGridRowClicked.bind(this);
    this.handleDraw = this.handleDraw.bind(this);

    this.getSurroundingProps = this.getSurroundingProps.bind(this);
    this.hackHandleIgnoreNextRowClick = this.hackHandleIgnoreNextRowClick.bind(this);

    const { embedded, property } = props;

    this.timer = null;
    this.contentRef = null;
    this.scrollHandle = embedded ? 'surrounding' : 'propertyDetail';
    this.offset = 10;

    this.state = {
      loadedContexts: {},
      position: defaultPosition,
      subject: null,
      columnApi: null,
      ...getColumns(props),
    };

    if (property) {
      const prop = property.toJS();
      const { id, imageUrls, address: { streetAddress, cityName, zip } = {}, lastSale: { saleAmount, saleDate } = {} } = prop;

      // Create subject object to be shown as the top, pinned row in the grid. Add nulls for any fields that should be suppressed.
      // Any MLS values present are suppressed since the public record sale information is always displayed.
      this.state.subject = { ...prop, id: 0, propertyId: id, imageUrl: imageUrls, streetAddress, cityName, zip, saleAmount, saleDate, mlsStatus: null, daysOnMarket: null };
    }

    this.gridProps = {
      onGridReady: this.handleGridReady,
      onColumnResized: this.handleColumnResized,
      onDragStopped: this.handleColumnDragStopped,
      onRowClicked: this.handleGridRowClicked,
      getRowNodeId: d => d.id,
      getRowHeight: ({ node }) => (node && node.detail && 52) || 42,
      suppressCellSelection: true,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      defaultColDef: { resizable: true, filter: true, sortable: true, menuTabs: [] },
      masterDetail: true,
      headerHeight: 40,
      detailCellRenderer: 'detailCellRenderer',
      groupSuppressRow: true,
      getRowStyle: ({ data: { type } = {} }) => ({ backgroundColor: { U: '#e4f3e6', M: '#e8f1fe' }[type] }),
      // detailRowHeight: 80,
      frameworkComponents: {
        indexCellRenderer: IndexCellRenderer,
        indexHeaderRenderer: IndexHeaderRenderer,
        addressCellRenderer: AddressCellRenderer,
        galleryCellRenderer: GalleryCellRenderer,
        detailCellRenderer: DetailCellRenderer,
      },
      context: {
        hackHandleIgnoreNextRowClick: this.hackHandleIgnoreNextRowClick,
        getSurroundingProps: this.getSurroundingProps,
      },
    };
  }

  componentWillMount() {
    load(this.props);
  }

  componentDidMount() {
    if (!this.props.embedded) bind(this.scrollHandle, this.handleScrollModal);
  }

  componentWillReceiveProps(props) {
    load(props);

    if (props.type.get('type') !== this.props.type.get('type') || props.type.getIn(['search', 'listingType']) !== this.props.type.getIn(['search', 'listingType'])) {
      /*
      In Offer Automator, getting this error which causes Exit Price step not to load. Running this async still produces the error but allows the screen to render.
      context.js?d4ce:191 ag-Grid: unable to find bean reference frameworkOverrides while initialising BeanStub
      general.js?ff3b:1759 Uncaught TypeError: Cannot read property 'addEventListenerOutsideAngular' of null
       */
      setTimeout(() => this.setLayout(props), 1);
    }
  }

  componentDidUpdate() {
    handleSurroundingTypeChange(this, this.props.type);
  }

  componentWillUnmount() {
    if (!this.props.embedded) unbind(this.scrollHandle, this.handleScrollModal);
  }

  setContentRef(ref) {
    this.contentRef = ref;
  }

  getSelectionSize() {
    return this.props.type.get('selection').size;
  }

  getSurroundingProps() {
    return this.props;
  }

  getGridSearch() {
    const search = {
      // this.gridApi?.getSortModel() ? this.gridApi?.getSortModel() :
      sortModel :  [],
      valueCols: this.state.columnApi.getAllGridColumns().filter(c => c.visible && !['selected', 'resultIndex', 'imageUrl'].includes(c.colDef.field)).map(({ colDef: { field, headerName } }) => ({ colId: field, headerName })),
    };
    return search;
  }

  setLayout(props, layout) {
    this.setState({ ...getColumns(props, layout) }, () => {
      this.gridApi.setColumnDefs([]); // Required workaround for bug: https://github.com/ag-grid/ag-grid/issues/2889
      this.gridApi.setColumnDefs(getColumnDefs(this.state.columns));
    });
  }

  search(search) {
    const { context, type, updateSurroundingPropertySearch } = this.props;
    // const { searchSurroundingProperties, searchComparables, type, propertyId, context, embedded } = this.props;
    // if (type.get('type') === 'COMP') searchComparables(propertyId, context, search, !embedded && this.props.types.getIn([1, 'loading']) ? () => searchSurroundingProperties(propertyId, context) : null);
    // else searchSurroundingProperties(propertyId, context, type.get('type'), search);
    updateSurroundingPropertySearch(context, search || type.get('search'), true);
  }

  killTimer() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }

  handleGridLayoutApply(layout) {
    this.setLayout(this.props, layout);
  }

  handleGridReady({ api, columnApi }) {
    this.gridApi = api;
    this.gridApi.setColumnDefs(getColumnDefs(this.state.columns));
    this.setState({ columnApi });
    this.dataVersion = 0;
    this.selectionVersion = 0;
  }

  handleColumnResized({ column }) {
    if (column) {
      const col = this.state.columns.find(c => c.field === column.colId);
      if (col) col.width = column.actualWidth;
    }
  }

  handleColumnDragStopped() {
    const { columns } = this.state;
    this.state.columnApi.getAllGridColumns().forEach(({ colId, pinned }, i) => {
      const col = columns.find(c => c.field === colId);
      if (col) {
        col.seq = i;
        col.pinned = pinned;
      }
    });
  }

  hackHandleIgnoreNextRowClick() {
    // TODO: Figure out why stopPropagation() don't work within grid cells
    this.hackIgnoreNextRowClick = true;
  }

  handleGridRowClicked({ api, node, data: { id } = {} }) {
    if (this.hackIgnoreNextRowClick) {
      setTimeout(() => {
        this.hackIgnoreNextRowClick = false;
      }, 1);
      return;
    }

    api.forEachNode((n) => {
      if (n !== node) n.setExpanded(false);
    });

    if (id) node.setExpanded(true);
  }

  handleScrollModal() {
    if (!this.props.embedded) {
      const { top } = this.contentRef.getBoundingClientRect();
      this.setState({ position: top < this.offset ? { position: 'absolute', top: this.offset - top, width: '100%' } : defaultPosition });
    }
  }

  prepareSearch(run, selectionRequired = true) {
    const { confirm, savedPropertyId, type } = this.props;
    const selectedIds = type.get('selection').reduce((ids, c, i) => `${ids}${i ? ',' : ''}${c.get('id')}`, '');

    if (selectedIds === '' && selectionRequired) confirm({ cancelLabel: null, question: 'You must select at least one comp.' });
    else run(type.get('search').merge({ savedPropertyId, selectedIds, layout: this.getGridSearch() }));
  }

  handleViewReportClick() {
    const { downloadComparables, propertyId } = this.props;

    this.prepareSearch(search => downloadComparables(propertyId, search));
  }

  handleExportClick(csv = false) {
    const { exportComparables, exportFlipComparables, propertyId, type } = this.props;

    this.prepareSearch(search => (type.get('type') === 'COMP' ? exportComparables : exportFlipComparables)(propertyId, search, csv));
  }

  handleSave() {
    const { saveSurroundingSearch, alert, checkSavedProperty, type } = this.props;

    checkSavedProperty(() => this.prepareSearch(search => saveSurroundingSearch(type.get('type'), search, () => alert('Comps search saved successfully.')), false));
  }

  handleSort({ fieldName: sort }) {
    const { sortSurroundingProperties, type, context } = this.props;
    sortSurroundingProperties(context, sort && sort === type.get('sort') ? `!${sort}` : sort);
  }

  handleReset() {
    const { resetSurroundingPropertySearch, context } = this.props;
    resetSurroundingPropertySearch(context);
  }

  handleChange(ev) {
    const { name, value, type } = ev.target || ev;
    const { updateSurroundingPropertySearch, context } = this.props;
    const search = this.props.type.get('search');
  
    let val = value;
  
    if (typeof value === 'string') {
      if (value.trim() === '') {
        val = null;
      } else if (
        ['squareFeetMin', 'squareFeetMax', 'lotSquareFeetMin', 'lotSquareFeetMax', 'bedroomsMin', 'bedroomsMax', 'yearBuiltMin', 'yearBuiltMax', 'ownershipLengthMin', 'ownershipLengthMax', 'estimatedEquityMin', 'estimatedEquityMax'].includes(name)
      ) {
        val = value.replace(/\D/g, ''); // Remove all non-numeric characters
      } else if (
        ['distanceFromSubjectMax', 'bathroomsMin', 'bathroomsMax', 'storiesMin', 'storiesMax', 'ltvRatioMin', 'ltvRatioMax'].includes(name)
      ) {
        // Allow numbers and one decimal
        val = value.replace(/[^0-9.]/g, ''); 
        const idx = val.indexOf('.');
        if (idx >= 0) {
          val = `${val.substr(0, idx + 1)}${val.substr(idx + 1).replace(/\./g, '')}`; // Ensure only one decimal
        }
      }
    }
  
    if (value instanceof Date) {
      if (
        ['saleDateMax', 'saleDateMin', 'recordingDateMin', 'recordingDateMax', 'auctionDateMin', 'auctionDateMax'].includes(name)
      ) {
        val = moment(value).valueOf();
      }
    }
  
    if (val !== search.get(name)) {
      const newSearch = search.set(name, val);
  
      this.killTimer();
      if (type !== 'text') {
        this.search(newSearch);
      } else {
        updateSurroundingPropertySearch(context, newSearch);
        this.timer = setTimeout(() => {
          this.killTimer();
          this.search(newSearch);
        }, 1000);
      }
    }
  }

  handleDraw(coords) {
    this.search(this.props.type.get('search').set('shapeDefinition', coords));
  }

  handleSaveGroup() {
    const { favorites, openAddToGroup, profile } = this.props;
    openAddToGroup({
      selectLoading,
      groups: favorites,
      addPropertyMode: true,
      size: this.getSelectionSize(),
      limit: profile.get('favoritePropertyAddLimit'),
      save: onComplete => this.save(PropertyGroupTypes.FAVORITE, '', onComplete),
      add: (name, onComplete) => this.save(PropertyGroupTypes.FAVORITE, name, onComplete),
    });
  }

  handleSaveList() {
    const { lists, openAddToGroup, profile } = this.props;
    openAddToGroup({
      selectLoading,
      groups: lists,
      addPropertyMode: true,
      size: this.getSelectionSize(),
      limit: profile.get('marketingListAddLimit'),
      groupType: 'Marketing List',
      add: (name, onComplete) => this.save(PropertyGroupTypes.MARKETING, name, onComplete),
    });
  }

  save(groupType, groupName, onComplete) {
    const { type, saveProperties } = this.props;
    const compMode = type.get('type') === 'COMP';
    const ids = type.get('selection').map(p => (compMode ? [p.get('id').substring(1)] : [p.get('propertyId'), p.get('id')])).toJS();

    saveProperties(ids, groupName, groupType, compMode ? null : type.get('type'), ({ response: { quantity } }) => onComplete(quantity));
  }

  handleTypeChange(type) {
    const { updateSurroundingPropertyType, types, context } = this.props;
    this.killTimer();
    updateSurroundingPropertyType(context, types.find(t => t.get('type') === type));
  }

  getdateFormat(value) {
    return value ? moment(value).toDate() : null
  }  

  render() {
    const { comparableLoading, loading: typeLoading, property, type, embedded } = this.props;
    const loading = comparableLoading || typeLoading;
    const { subject, columns, columnApi, layoutId } = this.state;
    const activeType = type.get('type');
    const search = type.get('search');
    const compMode = activeType === 'COMP';
    const flipMode = activeType === 'FLIP';
    const mlsMode = activeType === 'M';
    const propertyClassCode = search.get('propertyClassCode') || '';
    const listingType = search.get('listingType') || '';
    const mlsCompMode = compMode && listingType === 'M';
    const prCompMode = compMode && listingType === 'U';
    const layoutType = (compMode && listingType) || activeType;

    const btn = { loading };
    const textProps = { type: 'text', onChange: this.handleChange };
    const dateProps = { onChange: this.handleChange, noIcon: true, dateFormat: 'MM/dd/yyyy' };
    const cddProps = { skipCatcherCheck: true, filterClassName: css.dropdown, onChange: this.handleChange };
    const selected = type.get('selection').size;

    const defaultSituation = fromJS({ color: 'dimgray' });
    const properties = type.get('properties').map((c, i) => c.set('resultIndex', i + 1).set('pinColor', (c.get('saleSituation') || defaultSituation).get('color')));

    let saleDateMinPlaceholder = 'Min';
    let ltvMinPlaceholder = 'Min';
    let ltvMaxPlaceholder = 'Max';
    let distancePlaceholder = '1';
    let sudDivisionPlaceholder = 'Enter subdivision';
    if (compMode) {
      distancePlaceholder = '0.5';
      if (!search.get('saleDateMin')) saleDateMinPlaceholder = formatDate(moment().startOf('day').subtract(1, 'Y'));
    } else if (activeType === 'E') {
      ltvMinPlaceholder = '1';
      ltvMaxPlaceholder = '50';
    }

    return (
      <Panel className={css.surrounding}>
        <div className={classNames(css.content, { [css.embedded]: embedded })} ref={this.setContentRef}>
          <div className={css.left}>
            <div style={this.state.position}>
              <div className={css.criteria}>
                {!compMode ? null : (
                  <div className={css.dataset}>
                    <div className={css.inner}>
                      <SVG icon="iconDatabase" />
                      <div>Data Set:</div>
                      <div className={css.datasets}>
                        <Radio onChange={this.handleChange} label="Public Record" checked={listingType === 'U'} name="listingType" value="U" />
                        <Radio onChange={this.handleChange} label="MLS" checked={listingType === 'M'} name="listingType" value="M" />
                        <Radio onChange={this.handleChange} label="Both" checked={listingType === ''} name="listingType" value="" />
                      </div>
                    </div>
                    {!property.get('nonDisclosure') || listingType === 'M' ? null : <div className={css.nonDisclosure}>NOTE: Public Record sale prices are estimated due to non-disclosure state laws</div>}
                  </div>
                )}
                <div className={css.header}>
                  <div className={css.title}>{type.get('name').replace(/s$/, '')} Search</div>
                  <div className={css.actions}>
                    <RedLink onClick={this.handleReset}>Reset</RedLink>
                  </div>
                </div>
                <div className={css.fields}>
                  {SurroundingTypes[activeType].fields.split(',').map((type) => {
                    switch (type) {
                      case 'saleDate':
                        return (
                          <div className={css.range} key="saleDate">
                            <FormControlWraper label={`${prCompMode ? 'Sale ' : ''}Date Range`}>
                              <InputDate {...dateProps} selectsStart name="saleDateMin" placeholderText={saleDateMinPlaceholder} value={this.getdateFormat(search.get('saleDateMin'))} startDate={search.get('saleDateMin')} endDate={search.get('saleDateMax')} />
                            </FormControlWraper>
                            <FormControlWraper id="saleDateMax" showEmptyLabel>
                              <InputDate {...dateProps} selectsEnd name="saleDateMax" placeholderText="Max" value={this.getdateFormat(search.get('saleDateMax'))} startDate={search.get('saleDateMin')} endDate={search.get('saleDateMax')} />
                            </FormControlWraper>
                          </div>
                        );

                      case 'recordingDate':
                        return (
                          <div className={css.range} key="recordingDate">
                            <FormControlWraper label={{ M: 'Date Range' }[activeType] || 'Recording Date Range'}>
                              <InputDate {...dateProps} selectsStart name="recordingDateMin" placeholderText="Min" value={this.getdateFormat(search.get('recordingDateMin'))} startDate={search.get('recordingDateMin')} endDate={search.get('recordingDateMax')} />
                            </FormControlWraper>
                            <FormControlWraper id="saleDateMax" showEmptyLabel>
                              <InputDate {...dateProps} selectsEnd name="recordingDateMax" placeholderText="Max" value={this.getdateFormat(search.get('recordingDateMax'))} startDate={search.get('recordingDateMin')} endDate={search.get('recordingDateMax')} />
                            </FormControlWraper>
                          </div>
                        );

                      case 'auctionDate':
                        return (
                          <div className={css.range} key="auctionDate">
                            <FormControlWraper label="Auction Date Range">
                              <InputDate {...dateProps} selectsStart name="auctionDateMin" placeholderText="Min" value={this.getdateFormat(search.get('auctionDateMin'))} startDate={search.get('auctionDateMin')} endDate={search.get('auctionDateMax')} />
                            </FormControlWraper>
                            <FormControlWraper id="saleDateMax" showEmptyLabel>
                              <InputDate {...dateProps} selectsEnd name="auctionDateMax" placeholderText="Max" value={this.getdateFormat(search.get('auctionDateMax'))} startDate={search.get('auctionDateMin')} endDate={search.get('auctionDateMax')} />
                            </FormControlWraper>
                          </div>
                        );

                      case 'squareFeet':
                        return (
                          <div className={css.range} key="squareFeet">
                            <FormControlWraper id="squareFeetMin" label="Square Footage">
                              <input {...textProps} name="squareFeetMin" value={search.get('squareFeetMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="squareFeetMax" showEmptyLabel>
                              <input {...textProps} name="squareFeetMax" value={search.get('squareFeetMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );
                      case 'lotSquareFeet':
                        return (
                          <div className={css.range} key="lotSquareFeet">
                            <FormControlWraper id="lotSquareFeetMin" label="Lot Size">
                              <input {...textProps} name="lotSquareFeetMin" value={search.get('lotSquareFeetMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="lotSquareFeetMax" showEmptyLabel>
                              <input {...textProps} name="lotSquareFeetMax" value={search.get('lotSquareFeetMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'ownershipLength':
                        return (
                          <div className={css.range} key="ownershipLength">
                            <FormControlWraper id="ownershipLengthMin" label="Ownership (Years)">
                              <input {...textProps} name="ownershipLengthMin" value={search.get('ownershipLengthMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="ownershipLengthMax" showEmptyLabel>
                              <input {...textProps} name="ownershipLengthMax" value={search.get('ownershipLengthMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'flipLength':
                        return (
                          <div className={css.range} key="flipLength">
                            <FormControlWraper id="flipLengthMin" label="Flip Length (24 Months Max)">
                              <input {...textProps} name="flipLengthMin" value={search.get('flipLengthMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="flipLengthMax" showEmptyLabel>
                              <input {...textProps} name="flipLengthMax" value={search.get('flipLengthMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'ltvRatio':
                        return (
                          <div className={css.range} key="ltvRatio">
                            <FormControlWraper id="ltvRatioMin" label="LTV">
                              <input {...textProps} name="ltvRatioMin" value={search.get('ltvRatioMin') || ''} placeholder={ltvMinPlaceholder} />
                            </FormControlWraper>
                            <FormControlWraper id="ltvRatioMax" showEmptyLabel>
                              <input {...textProps} name="ltvRatioMax" value={search.get('ltvRatioMax') || ''} placeholder={ltvMaxPlaceholder} />
                            </FormControlWraper>
                          </div>
                        );

                      case 'estimatedEquity':
                        return (
                          <div className={css.range} key="estimatedEquity">
                            <FormControlWraper id="estimatedEquityMin" label="Estimated Equity">
                              <input {...textProps} name="estimatedEquityMin" value={search.get('estimatedEquityMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="estimatedEquityMax" showEmptyLabel>
                              <input {...textProps} name="estimatedEquityMax" value={search.get('estimatedEquityMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'distanceFromSubject':
                        return (
                          <FormControlWraper id="distance" label="Distance (Miles)" key="distanceFromSubject">
                            <input {...textProps} name="distanceFromSubjectMax" value={search.get('distanceFromSubjectMax') || ''} placeholder={distancePlaceholder} />
                          </FormControlWraper>
                        );

                      case 'bedrooms':
                        return (
                          <div className={css.range} key="bedrooms">
                            <FormControlWraper id="bedroomsMin" label="Bedrooms">
                              <input {...textProps} name="bedroomsMin" value={search.get('bedroomsMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="bedroomsMax" showEmptyLabel>
                              <input {...textProps} name="bedroomsMax" value={search.get('bedroomsMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'propertyClassCode':
                        return (
                          <FormControlWraper label="Property Class" key="propertyClassCode">
                            <CheckboxDropdown {...cddProps} name="propertyClassCode" options={PropertyClassOptions} value={propertyClassCode} searchable={false} />
                          </FormControlWraper>
                        );

                      case 'foreclosureStatus':
                        return (
                          <FormControlWraper label="Status" key="foreclosureStatus">
                            <CheckboxDropdown {...cddProps} name="foreclosureStatus" options={PreForeclosureStatusOptions} value={search.get('foreclosureStatus')} />
                          </FormControlWraper>
                        );
                      case 'subdivision':
                        return (
                          <FormControlWraper label="Subdivision" key="subdivision"
                            className={classNames({ [css.hidden]: mlsCompMode })}>
                            <input {...textProps} name="subdivision"
                              value={search.get('subdivision') || ''}
                              placeholder={sudDivisionPlaceholder} />
                          </FormControlWraper>
                        );
                      case 'excludeMultiParcel':
                        return (
                          <FormControlWraper label="Multi-Parcel Sales" key="excludeMultiParcel" className={classNames({ [css.hidden]: mlsCompMode })}>
                            <div className={css.radio}>
                              <Dropdown name="excludeMultiParcel" value={search.get('excludeMultiParcel') || ''} onChange={this.handleChange} options={ExcludeIncludeOptions} />
                            </div>
                          </FormControlWraper>
                        );
                      case 'purchaseMethod':
                        return (
                          <FormControlWraper label="Purchase Method" key="cashSale">
                            <div className={css.radio}>
                              <Dropdown name="cashSale" value={search.get('cashSale') || ''} onChange={this.handleChange} options={PurchaseMethodOptions} />
                            </div>
                          </FormControlWraper>
                        );

                      case 'bathrooms':
                        return (
                          <div className={css.range} key="bathrooms">
                            <FormControlWraper id="bathroomsMin" label="Bathrooms">
                              <input {...textProps} name="bathroomsMin" value={search.get('bathroomsMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="bathroomsMax" showEmptyLabel>
                              <input {...textProps} name="bathroomsMax" value={search.get('bathroomsMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'landUseCode':
                        return (
                          <FormControlWraper label="Property Type" key="landUseCode">
                            <CheckboxDropdown {...cddProps} name="landUseCode" value={search.get('landUseCode')} searchable direction="bottom" dropdownClassName={css.landUseDropdown} options={PropertyTypeOptions.filter(o => propertyClassCode === '' || !o.classCode || propertyClassCode.includes(o.classCode))} />
                          </FormControlWraper>
                        );

                      case 'yearBuilt':
                        return (
                          <div className={css.range} key="yearBuilt">
                            <FormControlWraper id="yearBuiltMin" label="Year Built">
                              <input {...textProps} name="yearBuiltMin" value={search.get('yearBuiltMin') || ''} placeholder="Min" />
                            </FormControlWraper>
                            <FormControlWraper id="yearBuiltMax" showEmptyLabel>
                              <input {...textProps} name="yearBuiltMax" value={search.get('yearBuiltMax') || ''} placeholder="Max" />
                            </FormControlWraper>
                          </div>
                        );

                      case 'mlsListingStatus':
                        return (
                          <FormControlWraper label={`${compMode ? '' : 'MLS'} Status`} key="mlsListingStatus">
                            {prCompMode || embedded ? <input {...textProps} value="Sold" disabled /> : <CheckboxDropdown {...cddProps} name="mlsListingStatus" options={MlsListingStatus} value={search.get('mlsListingStatus') || ''} />}
                          </FormControlWraper>
                        );

                      case 'ownerOccupied':
                        return (
                          <FormControlWraper label="Owner Occupied" key="ownerOccupied">
                            <Dropdown name="ownerOccupied" value={search.get('ownerOccupied') || ''} onChange={this.handleChange} options={YesNoOptions} />
                          </FormControlWraper>
                        );

                      case 'saleSituationCode':
                        return (
                          <FormControlWraper label={`${compMode ? 'Public Record ' : ''}Sale Situation`} key="saleSituationCode" className={classNames({ [css.hidden]: mlsCompMode })}>
                            <CheckboxDropdown {...cddProps}
                              name="saleSituationCode"
                              options={SaleSituationOptions}
                              value={search.get('saleSituationCode') || ''}
                            />
                          </FormControlWraper>
                        );

                      case 'lienTypeCode':
                        return (
                          <FormControlWraper label="Lien Type" key="lienTypeCode">
                            <CheckboxDropdown {...cddProps} name="lienTypeCode" options={LienTypeCode} value={search.get('lienTypeCode') || ''} />
                          </FormControlWraper>
                        );

                      case 'bankruptcyChapter':
                        return (
                          <FormControlWraper label="Bankruptcy Chapter" key="bankruptcyChapter">
                            <CheckboxDropdown {...cddProps} name="bankruptcyChapter" options={BankruptcyChapterOptions} value={search.get('bankruptcyChapter') || ''} />
                          </FormControlWraper>
                        );

                      default:
                        return <div key={type}>{type.startsWith('_') ? '' : type}</div>;
                    }
                  })}
                </div>
              </div>
              <div className={css.map}>
                <Map
                  properties={List()
                    .push(property)
                    .concat(properties)
                    .filter(c => !!c.get('latitude'))
                    .map(c => c.set('id', `${c.get('type', 'SB')}${c.get('id')}`))
                  }
                  onDraw={this.handleDraw}
                />
              </div>
              {mlsMode ? null : (
                <div className={css.legend}>
                  <div>Sale Situation Legend</div>
                  <div className={css.colors}>
                    {SaleSituations.map(s => (
                      <div className={css.color} key={s.name}>
                        <ColorDot color={s.color} isBig={false} />
                        <div>{s.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={css.right}>
            <LoadablePanel loading={type.get('loading')}>
              <div className={css.top}>
                <div className={classNames(css.titleContainer, { [css.tall]: flipMode })}>
                  <div className={css.title}>
                    {type.get('title') || type.get('label')} ({properties.size})
                    {!selected ? null : <span> - {selected} Selected</span>}
                  </div>
                  {!selected ? null : (
                    <div className={css.selected}>
                      {compMode || flipMode ? `${type.get('stats')}${!compMode || prCompMode ? '' : type.get('daysOnMarketStats')}` : ''}
                    </div>
                  )}
                </div>
                <div className={css.buttons}>
                  {embedded || (!compMode && !flipMode) ? null : <Button {...btn} onClick={this.handleSave} id={"save_comps"} disabled={loading || !selected}>Save Comps</Button>}
                  {embedded || flipMode ? null : <Button {...btn} onClick={this.handleSaveList} disabled={loading || !selected}>Add to List</Button>}
                  {embedded || compMode || flipMode ? null : <Button {...btn} onClick={this.handleSaveGroup} disabled={loading || !selected}>Add to Favorites</Button>}
                  {embedded || (!compMode && !flipMode) ? null : (
                    <DropdownMenu
                      text="Actions"
                      // icon="iconCheckSquare"
                      dropdownClassName={css.menuDropdown}
                      loading={loading || comparableLoading}
                      id="btn_comp_actions"
                      items={[
                        !compMode ? null : { label: 'View Comp Report', onSelect: this.handleViewReportClick },
                        !selected || !flipMode ? null : { label: 'Add to List', onSelect: this.handleSaveList },
                        !selected ? null : { label: 'Add to Favorites', onSelect: this.handleSaveGroup },
                        !(compMode || flipMode) ? null : { label: 'Export Comps', onSelect: this.handleExportClick },
                        !(compMode || flipMode) ? null : { label: 'Export Comps CSV', onSelect: () => this.handleExportClick(true) },
                      ]}
                    />
                  )}
                  <GridLayoutEditor
                    fixedColumns={fixedColumns}
                    columns={columns}
                    onApply={this.handleGridLayoutApply}
                    skipCatcherCheck
                    type="SURROUNDING_GRID"
                    listingType={layoutType}
                    gridApi={this.gridApi}
                    columnApi={columnApi}
                    layoutId={layoutId}
                    defaultName={`${SurroundingTypes[(compMode && listingType) || activeType].name} Layout`}
                  />
                </div>
              </div>
              <div className={classNames('ag-theme-balham', css.grid)}>
                <AgGridReact {...this.gridProps} pinnedTopRowData={subject ? [subject] : null} />
              </div>
            </LoadablePanel>
          </div>
        </div>
      </Panel>
    );
  }
}
/*
                      <div className={css.flexRow}>
                        <div className={css.range}>
                          <FormControlWraper id="storiesMin" label="Stories">
                            <input {...textProps} name="storiesMin" value={search.get('storiesMin') || ''} placeholder="Min" />
                          </FormControlWraper>
                          <span className={css.divider}> to </span>
                          <FormControlWraper id="storiesMax" showEmptyLabel>
                            <input {...textProps} name="storiesMax" value={search.get('storiesMax') || ''} placeholder="Max" />
                          </FormControlWraper>
                        </div>
                        {embedded === 0 ? (
                          <FormControlWraper id="propertyTypeCode" label="Property Type" className={css.relative}>
                            <CheckboxDropdown options={PropertyTypes} value={propertyTypeCode} />
                          </FormControlWraper>
                        ) : (
                          <FormControlWraper id="status" label="MLS Status" className={css.relative}>
                            <Dropdown name="mlsListingStatus" value={search.get('mlsListingStatus') || ''} onChange={this.handleChange} options={getOptionsValueLabel(MlsListingStatusNoneList, false, 'Any')} />
                          </FormControlWraper>
                        )}
                      </div>
                    </div>
 */

SurroundingProperties.propTypes = {
  embedded: PropTypes.bool,
  onSearchComplete: PropTypes.func,
};

SurroundingProperties.defaultProps = {
  embedded: false,
};

export default withProperty(SurroundingProperties, (state, props) => ({
  loading: selectLoading(state) || selectComparableLoading(state),
  comparableLoading: selectComparableLoading(state),
  stale: selectSurroundingPropertyStale(state, props.context),
  type: selectSurroundingPropertyType(state, props.context),
  types: selectSurroundingPropertyTypes(state, props.context),
  profile: selectProfile(state),
  lists: selectOriginalLists(state),
  favorites: selectOriginalFavorites(state),
  layouts: selectLayouts(state).filter(l => l.get('type') === 'SURROUNDING_GRID').toJS(),
}), {
  saveProperties,
  updateSurroundingPropertySearch,
  resetSurroundingPropertySearch,
  searchComparables,
  searchNeighbors,
  searchFlipComparables,
  searchSurroundingProperties,
  downloadComparables,
  exportComparables,
  exportFlipComparables,
  saveSurroundingSearch,
  sortSurroundingProperties,
  updateSurroundingPropertyType,
  updateSurroundingPropertySelection,
  confirm: Confirm.open,
  alert: Confirm.alert,
  openAddToGroup: AddToGroup.open,
});
