import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { List } from "immutable";
import formatServerUrl from "utils/URL";
import Confirm from "app/components/Confirm";

import Image from "components/base/Image";
import Link, { ThemeLink } from "components/Link";
import ColorDot from "components/ColorDot";
import { formatLongDate } from "utils/date/formatDate";
import Button, { SolidButton } from "components/Button";
import SVG from "components/base/SVG";
import { campaignPath } from "app/routes";
import { selectProfile } from "data/user";
import {
  saveCampaign,
  selectCampaign,
  selectLoading,
  CampaignTypes
} from "data/campaigns";

import Activities from "./Activities";
import css from "./style.scss";
import css2 from "../style.scss";

const totals = [
  {
    icon: "iconContacts",
    caption: "Total Recipients",
    prop: ["recipientTotal"]
  },
  {
    icon: "iconIdCard",
    caption: "Total Postcards Sent",
    type: CampaignTypes.POSTCARD,
    field: "deliveredQuantity"
  },
  // { icon: 'iconBullseye', caption: 'Total Impressions', type: CampaignTypes.AD, field: 'clickThroughQuantity' },
  {
    icon: "iconDesktop",
    caption: "Total Website Visits",
    prop: ["website", "visitQuantity"]
  }
];

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);

    this.handleEditName = this.handleEditName.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleCancelName = this.handleCancelName.bind(this);
    this.handleSaveName = this.handleSaveName.bind(this);
    this.handleToggleCampaign = this.handleToggleCampaign.bind(this);

    this.state = {
      id: null,
      totals: null,
      name: null
    };
  }

  componentDidMount() {
    this.processProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.processProps(props);
  }

  processProps(props) {
    const { campaign, loading } = props;
    const id = campaign.get("id");

    if (!loading && id && id !== this.state.id) {
      this.setState({
        totals: totals.map(({ icon, caption, prop, type, field }) => ({
          icon,
          caption,
          value: prop
            ? campaign.getIn(prop, 0)
            : campaign
                .get("campaigns", List())
                .filter(c => c.get("type") === type.code)
                .reduce((v, c) => v + c.get(field), 0)
        }))
      });
    }
  }

  handleEditName() {
    this.setState({ name: this.props.campaign.get("name") });
  }

  handleNameChange(ev) {
    this.setState({ name: ev.target.value });
  }

  handleCancelName() {
    this.setState({ name: null });
  }

  handleSaveName(ev) {
    const { campaign, saveCampaign, confirm } = this.props;
    const name = this.state.name.trim();

    ev.preventDefault();

    if (name === "")
      confirm({ cancelLabel: null, question: "Please enter a name." });
    else
      saveCampaign(
        campaign.set("name", this.state.name),
        this.handleCancelName
      );
  }

  handleToggleCampaign() {
    const { campaign, saveCampaign } = this.props;
    const active = campaign.get("active");

    this.props.confirm({
      question: `Are you sure you want to ${
        active ? "end" : "reinstate"
      } this campaign?`,
      onOk: () => saveCampaign(campaign.set("active", !active)),
      okLabel: `${active ? "End" : "Reinstate"} Campaign`
    });
  }

  render() {
    const { campaign, children, loading } = this.props;
    const { name, totals } = this.state;

    const id = campaign.get("id");
    const website = campaign.get("website");
    const websiteId = website.get("id");
    const active = campaign.get("active");
    const campaigns = campaign.get("campaigns", List());
    const url = website ? website.get("url") : "";
    const websiteActive = website.get("active");

    return (
      <div className={css.dashboard}>
        {children}
        <div className={classNames(css2.header, css.header)}>
          <div className={css2.left}>
            {name != null ? (
              <form onSubmit={this.handleSaveName} className={css.nameEdit}>
                <input
                  type="text"
                  autoComplete="off"
                  className={css.nameInput}
                  value={name}
                  onChange={this.handleNameChange}
                  style={{ width: `${5 + (name.length + 1) * 12.2}px` }}
                />
                <Button onClick={this.handleCancelName}>cancel</Button>
                <Button type="submit">save</Button>
              </form>
            ) : (
              <div className={css2.title}>
                <div>{campaign.get("name")}</div>
                <SVG
                  icon="iconPencil"
                  size={12}
                  onClick={this.handleEditName}
                />
              </div>
            )}
          </div>
          <div className={css2.right}>
            <Button loading={loading} onClick={this.handleToggleCampaign}>
              {active ? "End" : "Reinstate"} Campaign
            </Button>
            {!active || !campaigns.size ? null : (
              <SolidButton url={campaignPath(`${campaign.get("id")}/activity`)}>
                New Activity +
              </SolidButton>
            )}
          </div>
        </div>
        <div className={classNames(css2.content, css.content)}>
          <div className={css2.contentInner}>
            <div className={classNames(css.panel, css.topBar)}>
              <div className={css.campaignInfo}>
                <img src={"/assets/images/rocket.png"} alt="logo" className={css.logo} />
                <div>
                  <div className={css.title}>Campaign Start Date</div>
                  <div className={css.value}>
                    {formatLongDate(campaign.get("createDate"))}
                  </div>
                </div>
              </div>
              <div className={css.websiteInfo}>
                <div className={css.website}>
                  {/* <ColorDot
                    color={
                      (!websiteId && "#d1d1d1") ||
                      (websiteActive && "#0fb474") ||
                      "#d0021b"
                    }
                    isBig={false}
                  /> */}
                  <ThemeLink
                    url={url}
                    target={`website${websiteId}`}
                    disabled={!websiteId || !websiteActive}
                    text={
                      websiteId
                        ? url.substr(url.indexOf("www."))
                        : "Website not set up"
                    }
                  />
                </div>
                {!active && !websiteId ? null : (
                  <SolidButton url={campaignPath(`${id}/website`)}>
                    {websiteId ? "Edit Website" : "Setup Website"}
                  </SolidButton>
                )}
              </div>
            </div>
            <div className={classNames(css.panel, css.totalBar)}>
              {!totals
                ? null
                : totals.map(({ icon, caption, value }) => (
                    <div className={css.total} key={caption}>
                      <div className={css.top}>
                        <SVG icon={icon} className={css.icon} />
                        <div className={css.caption}>{caption}</div>
                      </div>
                      <div className={css.value}>
                        {value === null ? "—" : value}
                      </div>
                    </div>
                  ))}
            </div>
            {!campaigns.size ? (
              <Activities />
            ) : (
              <div className={css.items}>
                {campaigns.map(item => {
                  const type = CampaignTypes[item.get("type")];
                  const publishDate = item.get("publishDate");
                  const sizeName = item.get("sizeName");
                  const externalId = item.get("externalId");
                  const externalStatus = item.get("externalStatus");

                  return (
                    <Link
                      url={campaignPath(
                        `${id}/${type.route}/${item.get("id")}`
                      )}
                      className={css.card}
                      key={item.get("id")}
                    >
                      <div className={css.top}>
                        <div className={css.type}>
                          {type.name}
                          {!sizeName ? null : ` (${sizeName})`}
                        </div>
                        <div className={css.type}>
                          Order# {item.get("id")}
                          {!externalId ? null : `-${externalId}`}
                        </div>
                      </div>
                      <div className={css.top}>
                        <div className={css.name}>{item.get("name")}</div>
                        <div
                          className={
                            publishDate
                              ? css.statusPublished
                              : css.statusUnpublished
                          }
                        >
                          {publishDate ? "Published" : "Unpublished"}
                        </div>
                      </div>
                      <div className={css.lastEdit}>
                        <b>{publishDate ? "Published" : "Last Edited"}:</b>{" "}
                        {formatLongDate(publishDate || item.get("updateDate"))}
                        {!externalStatus ? null : (
                          <span className={css.status}>
                            <b>Status:</b> {externalStatus}
                          </span>
                        )}
                      </div>
                      <div className={css.bottom}>
                        <div className={css.preview}>
                          {!item.get("previewUrl") ? null : (
                            <Image
                              src={formatServerUrl(item.get("previewUrl"))}
                              alt="preview"
                            />
                          )}
                        </div>
                        <div className={css.info}>
                          {type.stats.map(stat => (
                            <div className={css.row} key={stat.field}>
                              <div className={css.value}>
                                {item.get(stat.field, 0)}
                              </div>
                              <div className={css.caption}>{stat.label}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      loading: selectLoading(state),
      campaign: selectCampaign(state),
      profile: selectProfile(state)
    }),
    {
      saveCampaign,
      confirm: Confirm.open
    }
  )(Dashboard)
);

/*

 */
